import { Injectable } from '@angular/core';
import { IExpansionPanelBoxData } from '../store/expansion-panel-box/expansion-panel-box.reducer';

@Injectable({
  providedIn: 'root'
})
export class SolutionService {

  // 테마
  public isDarkMode: boolean = true;
  
  // 마스킹
  public isMasking: boolean = false;
  
  // 정렬
  public components: IExpansionPanelBoxData[] = [];
  public isSorted!: boolean;
  public index: number = -1;

  // 선택한 폴더

  constructor() { }

}
