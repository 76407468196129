import { ComponentRef, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MrcDialogComponent } from "../../shared/components/molecules/mrc-dialog/mrc-dialog.component";
import { ChatBoxComponent } from "../../pages/chatflow/components/atom/chat-box/chat-box.component";
import { AnswerBalloonComponent } from "../../shared/components/molecules/answer-balloon/answer-balloon.component";
import { DataAbstract } from "../classes/data.abstract";
import { CategorySelectService } from './category-select.service';
import { ListComponent } from "../../pages/chatflow/components/atom/list/list.component";

@Injectable({ providedIn: 'root' })
export class DynamicLayoutService { 

  public childComponentRefs: Array<ComponentRef<DataAbstract>> = [];

  public chatBoxContainer!: ComponentRef<ChatBoxComponent>;
  public answerContainer!: ComponentRef<AnswerBalloonComponent>;
  public historyListContainer!: ComponentRef<ListComponent>;

  public chatScrollBody!: any;
  
  public inputMessage: string = ''; // 동적 input 컴포넌트 데이터
  public buttonName: string = '카테고리 선택'; // 동적 button 컴포넌트 데이터
  
  constructor(
    public dialog: MatDialog,
    public vectordbService: CategorySelectService,
  ) {}

  public selectCategory(){
    return this.dialog.open(MrcDialogComponent, {
      disableClose: true
    }).afterClosed();
  }

}