import { Injectable } from "@angular/core";
import { CategoryManageHttp, ICategoryRest } from "../http/category-manage.http";
import { FileHttp, IFileRest } from "../http/file.http";
import { UserHttp } from "../http/user.http";
import { CodeHttp } from "../http/code.http";
import { FileLegacyHttp } from "../http/file-legacy.http";
import { CategorManageLegacyHttp } from "../http/category-manage-legacy.http";
import { environment } from "../../../src/environments/environment";
import { ChatHistoryHttp, IChatHistoryRest } from "../http/chat-history.http";
import { ChatHistoryLegacyHttp } from "../http/chat-history-legacy.http";

@Injectable({providedIn: 'root'})
export class DalService{
    categoryHttp: ICategoryRest;
    fileHttp: IFileRest;
    userHttp: UserHttp;
    codeHttp: CodeHttp;
    chatHistoryHttp: IChatHistoryRest;
    
    constructor(){
        this.userHttp = new UserHttp();
        this.codeHttp = new CodeHttp();

        if(environment.isLegacy){
            this.categoryHttp = new CategorManageLegacyHttp();
            this.fileHttp = new FileLegacyHttp();
            this.chatHistoryHttp = new ChatHistoryLegacyHttp();
            return;
        }
        this.categoryHttp = new CategoryManageHttp();
        this.fileHttp = new FileHttp();
        this.chatHistoryHttp = new ChatHistoryHttp();
    }
}