<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>
    {{ content }}
</div>

@if (!isBtnDisabled) {
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close (click)="onClickNo()">{{ btnNo }}</button>
        <button mat-button  [mat-dialog-close]="true" (click)="onClickYes()">{{ btnYes }}</button>
    </div>
}