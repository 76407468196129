<div class="full-bottom-sheet">
  <div class="bottom-sheet-top">
    <h2 style="font-weight: 700;">프롬프트 시나리오</h2>
    <button mat-icon-button matTooltip="취소" (click)="closeBottomSheet()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-form-field>
    <mat-label>Prompt Suggestion</mat-label>
    <textarea 
      #promptText
      matInput 
      placeholder="Text"
      [value]="prompt"
      (keydown.enter)="getPrompt($event, promptText.value)"
    ></textarea>
  </mat-form-field>
  <button 
    mat-raised-button 
    color="primary" 
    matTooltip="보내기"
    (click)="getPrompt($event, promptText.value)"
  >보내기</button>
</div>