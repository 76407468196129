import { Injectable, Injector, Type } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject, filter, map, take } from 'rxjs';
import { DialogLayoutComponent } from './dialog-layout.component';
import { FormGroup } from '@angular/forms';
import { MembershipService } from '../../../membership.service';

export interface DynamicRequrestParam {
  service: Type<any>; // 주입받을 서비스명
  method: string; // 실행하고자 하는 메서드명
  paramType: Type<any>; // 실행하기 위한 파라미터값
}

export interface EventParam {
  event: string;
  param?: DynamicRequrestParam;
}

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private dialog:MatDialog,
    private injector: Injector,
    private membershipService: MembershipService
  ) {
    // 최초에 이벤트를 수신할 객체 필요
    this._eventListener$.push(new Subject<EventParam>());
   }

  private _dialogConfig: MatDialogConfig<any> = {
    width: '35%',
    minWidth: '350px',
    maxWidth: '500px',
    disableClose: true,
    data: {
      title: '회원가입'
    }
  };

  private _eventListener$: Subject<EventParam>[] = []; // 추가 모달창의 이벤트 수신자
  private _dialogRef: MatDialogRef<DialogLayoutComponent>[] = [];
  _formGroup$ = new BehaviorSubject<FormGroup>({} as FormGroup);

  setDialogConfig(config: MatDialogConfig){
    this._dialogConfig = config;
  }

  setData(data:any){
    this._dialogConfig.data = data;
  }

  get eventListener$(){
    return this._eventListener$[this._eventListener$.length - 1];
  }

  close() {
    const ref = this._dialogRef.pop();
    const sub = this._eventListener$.pop();
    
    sub?.unsubscribe();
    ref?.close();
  }

  // open(): MatDialogRef<DialogLayoutComponent>{
  open(data?: any) {
    if(data){
      this.setData(data);
    }

    this._eventListener$.push(new Subject<EventParam>());
    this._dialogRef.push(this.dialog.open(DialogLayoutComponent, this._dialogConfig));
  }

  submit<T>(data: DynamicRequrestParam){
    const {service, method, paramType} = data
    const serviceInstance = this.injector.get(service);
    this._formGroup$.pipe(
      filter(formGroup => {
        console.log(formGroup.valid);
        return true;
      }),
      map(formGroup => {
        return this.convertParam(paramType, formGroup)
      }),
      take(1),
    ).subscribe({
      next: param => {
        const observer = (serviceInstance as any)[method](param);
        this.execute(observer);
      }
    })

  }

  private convertParam(type: Type<any>, formGroup: FormGroup){
    const dto = this.membershipService.formConfigGenerator.generateDto(type);

    Object.keys(dto).forEach(key => {
      const value = formGroup.get(key)?.value;
      dto[key] = value ?? dto[key];
    })
    return dto;
  }

  private execute(observer: Observable<any>){
    observer.subscribe({
      next: result => {
        console.log('응답완료',result);
      }
    });
  }
}
