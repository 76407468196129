import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { GlobalService } from '../../../../core/services/global.service';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrl: './bottom-sheet.component.scss'
})
export class BottomSheetComponent {

  @ViewChild('promptText', { read: ElementRef, static: true }) promptText!: ElementRef;

  public prompt!: string; 

  constructor(
    public globalService: GlobalService, 
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.prompt = data.prompt;
  }

  getPrompt(event: KeyboardEvent | any, promptText: string) {
    if(event.isComposing) return;
    if(!promptText) return;
    event.preventDefault();

    this.globalService.prompt = promptText;

    if (!this.globalService.callPrompt) {
      this.closeBottomSheet();
      return;
    }

    this.closeBottomSheet();
  }

  closeBottomSheet() {
    this._bottomSheetRef.dismiss();
  }

}
