import { Component, ComponentRef, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subscription, forkJoin, take, tap } from 'rxjs';
import { passwordValidator } from '../../../../functions/formcontrol/password.validator';
import { TextfieldComponent } from '../../../../molecules/text-field/textfield.component';
import { KeyValue } from '@angular/common';
import { DynamincFormData, FormFieldData, FieldConfigData } from '../../../../interfaces/dynamic-form-option.interface';
import { ButtonFieldComponent } from '../../../../molecules/button-field/buttonfield.component';
import { FormfieldComponent } from '../../../../molecules/formfield/formfield.component';
import { PasswordConfirmfieldComponent } from '../../../../molecules/password-confirmfield/password-confirmfield.component';
import { PasswordfieldComponent } from '../../../../molecules/password-field/passwordfield.component';
import { DialogService } from '../dialog.service';

@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrl: './body.component.scss',
})
export class BodyComponent implements OnInit, OnDestroy {

  controlList: DynamincFormData[] = [
    {
      type: TextfieldComponent, 
      config: {
        title: '아이디',
        key: 'id',
        initValue: 'persona'
      } as FieldConfigData
    },
    {
      type: FormfieldComponent, 
      config: {
        title: '아이디',
        key: 'id',
        label: 'id',
        initValue: 'qweqwe',
        placeholder: '아이디를 입력해주세요',
        validators: ['requiredForm'],
        tooltip: '아이디'
      } as FormFieldData
    },
    {
      type: PasswordfieldComponent, 
      config: {
        title: '비밀번호',
        key: 'password',
        label: 'password',
        initValue: '111!!!qqq',
        placeholder: '비밀번호를 입력해주세요',
        validators: ['requiredForm'],
        tooltip: '비밀번호'
      } as FormFieldData
    },
    {
      type: PasswordConfirmfieldComponent, 
      config: {
        title: '비밀번호 확인',
        key: 'confirmPassword',
        label: 'confirmPassword',
        initValue: 'confirm',
        placeholder: '비밀번호를 다시 입력해주세요',
        validators: ['requiredForm'],
        tooltip: '비밀번호'
      } as FormFieldData
    },
    {
      type: FormfieldComponent,
      config: {
        title: '이름',
        key: 'name',
        label: 'name',
        initValue: '테스트',
        placeholder: '이름을 입력해주세요',
        validators: ['requiredForm'],
        tooltip: '이름'
      } as FormFieldData

    },
    {
      type: FormfieldComponent,
      config: {
        title: '이메일',
        key: 'email',
        label: 'email',
        initValue: 'test@aaa.com',
        placeholder: '이메일을 입력해주세요',
        validators: ['requiredForm', 'email'],
        tooltip: '이메일'
      } as FormFieldData
      
    },
    {
      type: FormfieldComponent, 
      config: {
        title: '소속',
        key: 'department',
        label: 'department',
        initValue: '로월드소프트',
        placeholder: '소속을 입력해주세요',
        validators: ['requiredForm'],
        tooltip: '소속'
      } as FormFieldData
      
    },
    {
      type: FormfieldComponent,
      config: {
        title: '전화번호',
        key: 'phone',
        label: 'phone',
        initValue: '01012341234',
        placeholder: '전화번호를 입력해주세요',
        validators: ['requiredForm'],
        tooltip: '전화번호'
      }as FormFieldData
    },
    // {
    //   type: RadioFieldComponent,
    //   config: {
    //     key: 'role',
    //     title: '권한 수준',
    //     radios: [
    //       { value: '200', label: '관리자' },
    //       { value: '300', label: '슈퍼관리자' },
    //     ],
    //     validators: ['requiredForm'],
    //   } as RadioFieldData

    // },
    {
      type: ButtonFieldComponent,
      config: {
        key: 'role',
        title: '모달 열기',
        initValue: 'test'
      } as FieldConfigData

    },
  ]

  formGroup: FormGroup = new FormGroup({}); 
  hide = true;
  hideConfirm = true;

  dynamicList!: KeyValue<string, ComponentRef<any>>[];

  @ViewChild('formContainer', {read: ViewContainerRef, static: true}) formContainer!: ViewContainerRef;

  subscription!: Subscription;

  constructor(
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    const observers = this.controlList.map(e => this.createFormControl(e));
    this.subscription = forkJoin(observers).subscribe({
      next: (result) => {
        this.dynamicList = result
      },
      complete: () => {
        this.checkForm();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * 비밀번호 확인 폼이 추가될 경우
   * 비밀번호 폼에 Valdator를 추가하고
   * 파라미터로 비밀번호 확인 폼의 DOM을 넘겨줌
   */
  checkForm(){ 
    const ref = this.dynamicList.find(e => e.key === 'confirmPassword')?.value.instance.viewInit.subscribe( (el: ElementRef) => {
      this.formGroup.get('password')!.addValidators(passwordValidator(el));
    });
    // this.membershipStore.setFormGroup(this.formGroup);
    this.setFormGroup();
  }

  /**
   * 폼그룹의 초기화가 완료될 경우 호출되는 메서드
   */
  setFormGroup(){
    this.dialogService._formGroup$.next(this.formGroup);
  }

  createFormControl(controlOption: DynamincFormData){
    return new Observable<KeyValue<string, ComponentRef<any>>>(subscriber => {
      const componentRef = this.formContainer.createComponent(controlOption.type);
      componentRef.setInput('initData', controlOption.config);
      componentRef.instance.event?.pipe(
        tap((control: FormControl[]) => {
          
          this.formGroup.addControl(controlOption.config.key, control);
          this.formGroup.updateValueAndValidity();
        }),
        take(1)
      ).subscribe();

      subscriber.next({key: controlOption.config.key, value: componentRef});
      subscriber.complete();
    })
  }

}
