import { Injectable } from '@angular/core';
import { IFile } from '../interfaces/file/file.interface';
import { ICategoryInfo } from '../interfaces/category/category.interface';
import { ITreeDatasource } from '../../../components/treeview/tree.interface';
import { TreeService } from '../../../components/treeview/service/tree.service';
import { SlotSimulatorComponent } from '../../slots/components/simulator/simulator.component';

export interface ICategoryData {
  id: number;
  idPath: string;
  docType: string;
  namePath: string;
}
@Injectable({ providedIn: 'root' })
export class CharacterService {
  messageValue: string = '';

  selectedTree!: ITreeDatasource;
  treeService!: TreeService<ITreeDatasource>;
  currentSimulator!: SlotSimulatorComponent;

  files: IFile[] = [];
  categories: Array<ICategoryInfo> = [];
  categoryData: ICategoryData = {
    id: 0,
    idPath: '',
    docType: 'default',
    namePath: 'Please select a category.', // 변경: '카테고리를 선택해주세요.' -> 'Please select a category.'
  };

  select(data: ITreeDatasource, treeService: TreeService<ITreeDatasource>) {
    this.selectedTree = data;
    this.treeService = treeService;
  }

  send() {
    this.create();
  }

  create() {
    if (!this.selectedTree) return;

    const id = this.getNextId();
    const childData = {
      id: id,
      name: this.messageValue,
      rootId: this.selectedTree.id,
      depth: this.selectedTree.depth + 1,
      steps: 0,
      type: 'local',
      children: [],
    };

    const found = this.treeService.findMe(this.selectedTree.id);
    found!.children.push(id); // Add received data to children 

    const { steps, children, ...rest } = childData;
    this.treeService.datasource.push({ ...rest, children: children }); 
    this.setCategories();
  }

  rename(name: string) {
    const category = this.categories.find((d) => d.id === this.selectedTree.id);
    if (category) {
      category.name = name;
    }
    localStorage.setItem('character', this.categories.stringify());
  }

  load() {
    this.categories = this.loadCategories();
    return this.categories;
  }

  getNextId() {
    const allIds = this.categories.flatMap((category: any) => [
      category.id,
      ...category.children,
    ]);
    return Math.max(...allIds) + 1;
  }

  setCategories() {
    localStorage.setItem('character', this.categories.stringify());
  }

  loadCategories() {
    const categories = localStorage.getItem('character');
    if (categories == null) {
      this.categories = [
        {
          id: 1,
          name: 'create a object', // This remains the same as it's likely part of the application's logic
          rootId: 1,
          depth: 0,
          type: 'local',
          children: [],
        },
      ];
      localStorage.setItem('character', this.categories.stringify());
    }

    return JSON.parse(localStorage.getItem('character') as string);
  }

  private selectCategoryItems(rootId: number): any[] {
    this.load();
    return this.categories
      .filter((d) => d.rootId === rootId)
      .map((d) => ({ title: d.name, desc: d.data?.contentValue }));
  }

  selectJob() {
    const items = this.categories
    .filter((d) => d.type === "Item")
    .map((d) => ({ title: d.name, desc: d.data?.contentValue }));
    return {
      title: 'Select Job', // 변경: '직업선택' -> 'Select Job'
      desc: 'Please select a job', // 변경: '직업을 선택해 주세요' -> 'Please select a job'
      size: { width: 100, height: 100, position: { x: 0, y: 0 } },
      items: items,
    };
  }

  selectItem() {
    this.load();
    const items = this.categories
    .filter((d) => d.type === "item")
    .map((d) => ({ title: d.name, desc: d.data?.contentValue }));
    return {
      title: 'Select Item', // 변경: '아이템 선택' -> 'Select Item'
      desc: 'Please select an item to acquire', // 변경: '획득할 아이템을 선택해 주세요' -> 'Please select an item to acquire'
      size: { width: 100, height: 100, position: { x: 0, y: 0 } },
      items: items,
    };
  }

  selectMember() {
    const items = this.selectCategoryItems(2);
    return {
      title: 'Select Companion', // 변경: '동료 선택' -> 'Select Companion'
      desc: 'Please select a companion', // 변경: '동료를 선택해 주세요' -> 'Please select a companion'
      size: { width: 100, height: 100, position: { x: 0, y: 0 } },
      items: items,
    };
  }

  selectMission() {
    const items = this.selectCategoryItems(55);
    return {
      title: 'Select Mission', // 변경: '미션 선택' -> 'Select Mission'
      desc: 'Please select a mission to proceed', // 변경: '진행할 선택해 주세요' -> 'Please select a mission to proceed'
      size: { width: 100, height: 100, position: { x: 0, y: 0 } },
      items: items,
    };
  }

  selectFight() {
    this.load();
    const items: any[] = [];
    const result = this.categories.filter(d => d.type === "character");
    const halfLength = Math.ceil(result.length / 2); 
    result.forEach((d, index) => {
      items.push({
        title: d.name,
        key: index < halfLength ? 0 : 1, 
        desc: d.data.contentValue,
      });
    });

    return {
      title: 'Battle Mode', // 변경: '전투 모드' -> 'Battle Mode'
      desc: 'Battle character information is gathered here.', // 변경: '전투 캐릭터 정보가 모여 있습니다.' -> 'Battle character information is gathered here.'
      size: { width: 100, height: 100, position: { x: 0, y: 0 } },
      items: items,
    };
  }
}