import { FormFieldConfig } from "../../interfaces/dynamic-form.interface";
import { FieldOption } from "./field.option";
import { ValidatorMap, validatorMapper } from "../mapper/validator.mapper";

export class FormFieldOption extends FieldOption{
    override config: Required<FormFieldConfig> = {
        validators: [],
        label: "",
        placeholder: "",
        tooltip: "",
        key: "",
        title: "",
        initValue: undefined
    };

    // validatorMapper!:ValidatorMap;

    validators(validatorList: Array<keyof ValidatorMap>): this {
        const validators = validatorMapper(this.config.key);
        
        this.config.validators = validatorList.map(val => validators[val]());
        return this;
    }

    label(label: string): this {
        this.config.label = label;
        return this;
    }

    placeholder(placeHolder: string): this {
        this.config.placeholder = placeHolder;
        return this;
    }

    tooltip(tooltip: string): this {
        this.config.tooltip = tooltip;
        return this;
    }

    override build(): Required<FormFieldConfig> {
        return this.config;
    }


}