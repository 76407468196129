import { KeyValue } from '@angular/common';
import { inject } from '@angular/core';
import { LayoutService } from '../../../../core/services/layout.service';

export interface IValue {
  [key: string]: any;
  id: string;
  type: 'row' | 'box',
  name: string;
  value: string;
  size: {
    width: string;
    height: string;
  };
  position: {
    x: number;
    y: number;
  };
  cell: {
    colSpan: number;
  };
  path: string;
  boxs: IValue[];
}

export class SketchflowData {
  
  private readonly layoutService = inject(LayoutService);

  public chatbot: KeyValue<string, IValue[]> = {
    key: 'chatbot',
    value: [
      {
        id: '0',
        type: 'row',
        name: 'row1',
        value: '',
        size: {
          width: this.layoutService.getInnerWidth(),
          height: '200px',
        },
        position: {
          x: 10,
          y: 10 
        },
        cell: {
          colSpan: 0,
        },
        path: '',
        boxs: []
      },
      {
        id: '1',
        type: 'row',
        name: 'row2',
        value: '',
        size: {
          width: this.layoutService.getInnerWidth(),
          height: '200px',
        },
        position: {
          x: 10,
          y: 10 
        },
        cell: {
          colSpan: 0,
        },
        path: '',
        boxs: []
      },
      {
        id: '2',
        type: 'row',
        name: 'row3',
        value: '',
        size: {
          width: this.layoutService.getInnerWidth(),
          height: '200px',
        },
        position: {
          x: 10,
          y: 10 
        },
        cell: {
          colSpan: 0,
        },
        path: '',
        boxs: []
      }
    ]
  }

  public login: KeyValue<string, IValue[]> = {
    key: 'login',
    value: [
      {
        id: '0',
        type: 'row',
        name: 'row1',
        value: '',
        size: {
          width: this.layoutService.getInnerWidth(),
          height: '200px',
        },
        position: {
          x: 10,
          y: 10 
        },
        cell: {
          colSpan: 0,
        },
        path: '',
        boxs: []
      }
    ]
  }

}