import { Injectable } from "@angular/core";
import { SlotComponent } from "../slot/slot.component";
import { GlobalService } from "../../../core/services/global.service";

@Injectable({ providedIn: 'root' })
export class PromptService {

  constructor(
  ) {
  }

  generateCharacterPrompt(title?:string) {
    const prompt = `
  다음 형식에 맞추어 소설을 캐릭터 정보를 만들어 주세요.
  
  \`\`\`json
  {
    "title": "${title ?? '캐릭터 컨셉'}",
    "genre": "",
    "summary": "캐릭터들의 대한 설명",
    "type": "title",
    "content": [
      {
        "name": "[캐릭터 특성에 대한 유니코드] 캐릭터 이름-캐릭터 팀명",
        "content": {
            "name": "[캐릭터 팀에 대한 유니코드]캐릭터 이름", // 캐릭터의 이름
            "level": 20, // 캐릭터의 현재 레벨
            "role": "성직자", // 캐릭터의 역할 또는 클래스 (예: 전사, 마법사, 성직자)
            "health": 200, // 캐릭터의 현재 체력
            "maxHealth": 200, // 캐릭터의 최대 체력
            "attackPower": 8, // 캐릭터의 공격력
            "defensePower": 10, // 캐릭터의 방어력
            "isAttacking": false, // 캐릭터가 현재 공격 중인지 여부 (true/false)
            "isDefending": false, // 캐릭터가 현재 방어 중인지 여부 (true/false)
            "isDebuffed": false, // 캐릭터에게 현재 디버프가 적용되어 있는지 여부 (true/false)
            "skills": [ // 캐릭터가 사용할 수 있는 스킬 목록
                { 
                    "name": "회춘신공", // 스킬 이름
                    "damage": -20, // 스킬의 피해량 (음수 값은 치유 효과)
                    "targets": 1, // 스킬이 영향을 미치는 대상의 수
                    "cooldown": 0, // 스킬을 다시 사용하기 전에 필요한 쿨다운 턴 수 (현재 쿨다운)
                    "maxCooldown": 5, // 스킬의 최대 쿨다운 턴 수
                    "isHeal": true // 스킬이 치유 스킬인지 여부 (true/false)
                },
                { 
                    "name": "정화", 
                    "damage": 0, 
                    "targets": 1, 
                    "buff": { "allDebuffs": false }, // 스킬이 부여하는 버프 효과 정보 (있는 경우)
                    "cooldown": 0, 
                    "maxCooldown": 6, 
                    "isHeal": true 
                }
            ],
            "items": [ // 캐릭터가 보유한 아이템 목록
                { 
                    "name": "백옥지팡이", // 아이템 이름
                    "additionalSkills": [ // 아이템이 부여하는 추가 스킬 목록 (있는 경우)
                        { 
                            "name": "회복의 기원", 
                            "damage": -15, 
                            "targets": 2, 
                            "cooldown": 0, 
                            "maxCooldown": 3, 
                            "isHeal": true 
                        }
                    ]
                },
                { 
                    "name": "천잠사", 
                    "skillBlockCount": 1 // 아이템이 막을 수 있는 스킬 횟수 (있는 경우)
                }
            ]
        } ,
        "type": "character",
        "children": [ {
                  "name": "[아이템을 뜻하는 유니코드] 캐릭터가 보유한 아이템",
                  "content": "아이템의 특성",
                  "type": "item"
            }
        ]
      },
    ],
  }
  \`\`\`
  
  **추가 요청 사항:**
  * **캐릭터:** 최소 4명을 만들어주고 절반은 착한팀 절반은 나쁜팀
  * **아이템:** 캐릭터의 자녀에 꼭 아이템을 넣어줘

  **참고:**
  * 위 형식에 맞춰 JSON 형태로 답변을 생성해주세요.
  `;
  
    return prompt;
  }


  generateNovelPrompt() {
    const prompt = `
  다음 형식에 맞추어 소설을 생성해주세요.
  
  \`\`\`json
  {
    "title": "소설 제목",
    "genre": "[소설 장르 유니코드] 소설 장르",
    "summary": "소설 요약",
    "type": "title",
    "content": [
      {
        "name": "[각 장의 내용 뜻하는 유니코드] 각 장의 제목",
        "content": "요약된 스토리",
        "type": "story",
        "children": [
                  "name": "[각 장의 세부 내용 뜻하는 유니코드] 각 장의 세부 내용 제목",
                  "content": "요약된 스토리",
        ]
      },
    ],
  }
  \`\`\`
  
  **추가 요청 사항:**
  
  * **소설 제목:** 흥미롭고 독창적인 제목으로 부탁해
  * **소설 장르:** 판타지, 로맨스, 미스터리 중 하나를 선택해
  * **줄거리:** 예측 불가능한 반전과 감동적인 결말을 포함시켜줘
  * **분량:** 500자 내외로 부탁해
  
  **참고:**
  
  * 선정적이고 폭력적인 내용은 포함하지마*
  * 위 형식에 맞춰 JSON 형태로 답변을 생성해주세요.
  `;
  
    return prompt;
  }

}