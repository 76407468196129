import { ComponentRef, Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Store } from '@ngrx/store';
import { BottomSheetComponent } from '../../pages/chatbot/components/bottom-sheet/bottom-sheet.component';
import { ChatBoxComponent } from '../../pages/chatflow/components/atom/chat-box/chat-box.component';
import { MembershipService } from '../../pages/membership/membership.service';
import { Behavior } from '../behaviors/abstract/abstract.behavior';
import { Chatflow } from '../chatflows/abstract/chatflow.abstract';
import { LiskovChatflow } from '../chatflows/liskov.chatflow';
import { LiskovChatflowModel } from '../models/liskov.chatflow.model';
import { LiskovModel } from '../models/liskov.model';
import { CurrentUserService } from './current-user.service';
import { DalService } from './dal.service';
import { DynamicLayoutService } from './dynamic-layout.service';
import { FactoryService } from './factory.service';
import { HttpService } from './http.service';
import { LayoutService } from './layout.service';
import { LogicService } from './logic.service';
import { SocketService } from './socket.service';
import { SolutionService } from './solution.service';
import { StoreService } from './store.service';
import { CategorySelectService } from './category-select.service';
import { HistoryService } from './history.service';
import { AnswerBalloonComponent } from '../../shared/components/molecules/answer-balloon/answer-balloon.component';
import { DataAbstract } from '../classes/data.abstract';
import { Router } from '@angular/router';
import { FlowService } from '../../pages/slots/service/flow.service';
import { AiService } from '../../pages/slots/service/ai.service';
import { SlotService } from '../../pages/slots/service/slot.service';
import { CategoryService } from '../../pages/category-manage/services/category.service';
import { FormService } from '../../pages/slots/form/dynamic-form/dynamic-form.component';
import { SlotFormService } from '../../pages/slots/service/slot.form.service';
import { CharacterService } from '../../pages/category-manage/services/character.service';
import { GeminiService } from '../../pages/slots/service/gemini.service';
import { PromptService } from '../../pages/slots/service/prompt.service';
import { CommandService } from '../../pages/slots/service/command.service';

@Injectable({ providedIn: 'root' })
export class GlobalService {
  public logicType: string = 'mrc';
  public prompt: string = '';

  public behaviorType: string = 'default';

  public callPrompt: boolean = false;
  public index!: number;
  public isDragging: boolean = false;

  public liskov!: LiskovModel;
  public behavior!: Behavior;

  public liskovChatflow!: LiskovChatflowModel;
  public chatflow!: Chatflow;

  // TODO : 환경공단 시연 임시용
  public e_test = [];

  public currentCategoryService = this.categoryService;
  public geminiInput: string = '';

  public isWhite : boolean = true;

  constructor(
    private router: Router,
    private _bottomSheet: MatBottomSheet,
    public httpService: HttpService,
    public socketService: SocketService,
    public factoryService: FactoryService,
    public solutionService: SolutionService,
    public layoutService: LayoutService,
    public storeService: StoreService,
    public logicService: LogicService,
    public store: Store,
    public vectordbService: CategorySelectService,
    public dalService: DalService,
    public membershipService: MembershipService,
    public currentUser: CurrentUserService,
    public dynamicLayoutService: DynamicLayoutService,
    public historyService: HistoryService,
    public flowService: FlowService,
    public aiService: AiService,
    public slotService: SlotService,
    public slotFormService: SlotFormService,
    public categoryService: CategoryService,
    public characterService: CharacterService,
    public geminiService: GeminiService,
    public promptService: PromptService,
    public commandService: CommandService,
  ) {
    this.httpService.GetJson('./assets/json/chatflow.json').subscribe((d) => {
      this.liskovChatflow = new LiskovChatflowModel(router, d, this);
      this.chatflow = new LiskovChatflow(this.liskovChatflow);
    });

    // TODO : 환공공단 시연 임시용.(재개발 필요)
    this.httpService.GetJson('./assets/json/e_test.json').subscribe((d) => {
      this.e_test = d;
    });
  }

  public openBottomSheet() {
    const bottomSheetRef = this._bottomSheet.open(BottomSheetComponent, {
      data: { prompt: this.prompt },
    });
    return bottomSheetRef;
  }
}
