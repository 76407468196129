import { Component, EventEmitter, Output } from '@angular/core';
import { DataAbstract } from '../../../../../core/classes/data.abstract';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent extends DataAbstract {

  public x: number = 0;
  public y: number = 0;

  @Output() click = new EventEmitter<any>();

  public override dataInit(): void {
    this.x = this.data.position.x;
    this.y = this.data.position.y;
  }

  public onClick(event: MouseEvent) {
    this.click.emit();
  }

}
