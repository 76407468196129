import { IChatflowLayout } from "../interface/layout.chatflow.interface";
import { ButtonComponent } from '../../../pages/chatflow/components/atom/button/button.component';
import { InputComponent } from "../../../pages/chatflow/components/atom/input/input.component";
import { ChatBoxComponent } from "../../../pages/chatflow/components/atom/chat-box/chat-box.component";
import { Chatflow } from "./chatflow.abstract";
import { LiskovChatflowModel } from "../../models/liskov.chatflow.model";
import { ChipsComponent } from "../../../pages/chatflow/components/atom/chips/chips.component";
import { IconButtonComponent } from "../../../pages/chatflow/components/atom/icon-button/icon-button.component";
import { CardComponent } from "../../../pages/chatflow/components/atom/card/card.component";
import { ExpansionPanelComponent } from "../../../pages/chatflow/components/atom/expansion-panel/expansion-panel.component";
import { TextComponent } from "../../../pages/chatflow/components/atom/text/text.component";
import { ButtonNameComponent } from "../../../pages/chatflow/components/atom/button-name/button-name.component";

export abstract class ChatflowLayout extends Chatflow implements IChatflowLayout {

  constructor(public override liskovChatflow: LiskovChatflowModel) {
    super(liskovChatflow);
  }

  override draw(index: number): void {
    if (index === this.jsonData.value.length) return;

    (this as any)[this.jsonData.value[index].type](this.jsonData.value[index].node);
  }
  
  button(data: any) {
    const button = this.liskovChatflow.service.factoryService.createComponent(
      ButtonComponent,
      this.container,
      data
    );
    this.subscription.add(button.instance.click.subscribe(d => {
      console.log('button click', d);
    }));
    this.draw(++this.liskovChatflow.service.index);
  }

  iconButton(data: any) {
    const iconButton = this.liskovChatflow.service.factoryService.createComponent(
      IconButtonComponent,
      this.container,
      data
    );
    this.subscription.add(iconButton.instance.click.subscribe(d => {
      console.log('iconButton click', d);
    }));
    this.draw(++this.liskovChatflow.service.index);
  }

  chips(data: any) {
    const chips = this.liskovChatflow.service.factoryService.createComponent(
      ChipsComponent,
      this.container,
      data
    );
    this.subscription.add(chips.instance.selectChange.subscribe(d => {
      console.log('chips selectChange', d)!
    }));
    this.draw(++this.liskovChatflow.service.index);
  }

  input(data: any) {
    const input = this.liskovChatflow.service.factoryService.createComponent(
      InputComponent,
      this.container,
      data
    );
    this.subscription.add(input.instance.enter.subscribe((d: any) => {
      console.log('input enter', d);
    }));
    this.draw(++this.liskovChatflow.service.index);
  }

  chatBox(data: any) {
    this.liskovChatflow.service.dynamicLayoutService.chatBoxContainer = this.liskovChatflow.service.factoryService.createComponent(
      ChatBoxComponent,
      this.container,
      data
    );
    this.draw(++this.liskovChatflow.service.index);
  }

  card(data: any) {
    this.liskovChatflow.service.factoryService.createComponent(
      CardComponent,
      this.container,
      data
    );
    this.draw(++this.liskovChatflow.service.index);
  }

  expansionPanel(data: any) {
    this.liskovChatflow.service.factoryService.createComponent(
      ExpansionPanelComponent,
      this.container,
      data
    );
    this.draw(++this.liskovChatflow.service.index);
  }

  text(data: any) {
    this.liskovChatflow.service.factoryService.createComponent(
      TextComponent,
      this.container,
      data
    );
    this.draw(++this.liskovChatflow.service.index);
  }

  buttonName(data: any) {
    this.liskovChatflow.service.factoryService.createComponent(
      ButtonNameComponent,
      this.container,
      data
    );
    this.draw(++this.liskovChatflow.service.index);
  }

}