import { EventEmitter } from "@angular/core";
import { FormControl, ValidatorFn } from "@angular/forms";
import { FormFieldConfig } from "../../interfaces/dynamic-form.interface";
import { FormFieldOption } from "../../util/generator/form.field.option";
import { FormFieldData } from "../../interfaces/dynamic-form-option.interface";
import { DynamicField } from "./dynamic-field.abstract";

/**
 * 폼 영역 데이터 바인딩 클래스
 */
export abstract class AbstractFormFeild extends DynamicField {
    abstract event: EventEmitter<FormControl>;
    config!: FormFieldConfig;
    control!: FormControl;

    init(initData: FormFieldData){
        const { key, title, initValue, label, placeholder, tooltip, validators } = initData;
        const config = this.generator.generate(FormFieldOption)
            .key(key).title(title).initValue(initValue).label(label)
            .placeholder(placeholder).tooltip(tooltip).validators(validators).build();
        this.config = config;
        this.controlInit(config.validators);
    }
    

    controlInit(validators: ValidatorFn[]){
        this.control = new FormControl(this.config.initValue, [...validators]);
        this.event.next(this.control);
    }
}