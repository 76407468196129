import { Injectable } from "@angular/core";
import { ICategoryInfo } from "../../pages/category-manage/interfaces/category/category.interface";
import { CategoryCode } from "../../pages/category-manage/const/catgegory-root-code.const";

export type TCheckedVectordb = {
    [key in string] : ICheckedData;//선택 안하면 null이다.
}

interface ICheckedData{
    path: string;
    id: number;
    myData: ICategoryInfo;
    type: string;
}

export interface IMrcSaved{
    ids: Array<number>;
    paths: Array<string>;
}

@Injectable({providedIn: 'root'})
export class CategorySelectService{
    currentCategory: ICheckedData | null = null;//선택되지 않았을 때는 null이 돼야 함.

    ids: Array<number> = [];
    paths: Array<string> = [];

    //카테고리 타입 별로 어떤 데이터를 선택했는지 담아놓음. 
    selectedCategories: TCheckedVectordb  = {};

    constructor(){
        const categoryCode = new CategoryCode();
        categoryCode.getSelectables().forEach(d =>{
            this.selectedCategories[d.key] = {
                id: -1,
                path: '',
                myData: {
                    id: -1,
                    depth: -1, //depth 계산을 
                    children:[],
                    name: '',
                    rootId: -1,
                    type: ''
                },
                type: d.key
            }
        });
        console.log('selectedCategories', this.selectedCategories);
        
    }

    add(type: string, id: number, path: string, myData: ICategoryInfo){
        return this.selectedCategories[type] = {
            id: id,
            path: path,
            myData: myData,
            type: type
        }
    }


    remove() {
        this.currentCategory = null;
    }

    /**
     * add remove 구분하기
     * 방법1. store에서 구분하기(clicked, unclicked)
     * 방법2. 그냥 여기나 다른 함수에서 구분하기 (collections에 데이터가 추가됐는지 확인 -> 추가됐음 제거하고 없으면 추가하고)
     */
}