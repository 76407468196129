<div class="text-left">
  <div class="flex flex-v-center color-000 font-bold mb-20">
    <img src="./assets/svg/light.svg" width="20px"  alt="" class="mr-5">
    OlamSpace
  </div>
  <div class="chat-box-answer">
    <ng-container #container></ng-container>
    <div *ngIf="isProgress">
      <mat-progress-bar mode="buffer"></mat-progress-bar>
      <div [innerHTML]="answer | safeHtml"></div>
    </div>
    <div *ngIf="!isProgress">
        <div [innerHTML]="answer | safeHtml"></div>
    </div>
  </div>
</div>
