import { Observable } from "rxjs";
import { HttpService } from "../services/http.service";
import { User } from "../../pages/membership/interfaces/user.interface";
import { IBaseResponse } from "../../pages/category-manage/interfaces/iBaseResponse.dto";
import { inject } from "@angular/core";

export interface IUserHttp{
    getUsers(data: any): void;
}

export class UserHttp implements IUserHttp {

    private readonly httpService = inject(HttpService);

    getUsers(): Observable<IBaseResponse<Array<User>>> {
        return this.httpService.Get('/users') as Observable<IBaseResponse<Array<User>>>;
    }

}