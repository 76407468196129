import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function passwordMatchValidate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if(!control.parent){
            return null;
        }
        
        const password = control.parent.get('password')?.value;
        const confirmPassword = control.parent.get('confirmPassword')?.value;

        if(password !== confirmPassword){
            return { message: '비밀번호가 일치하지 않습니다.'}
        }
        

        return password === confirmPassword ? null : { message: '비밀번호가 일치하지 않습니다.' };
    }
}