import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

/**
 * 
 * @param : 폼 컨트롤에 들어가야할 값
 * @returns : {message: string} | null
 */
export function requiredForm(key: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        
        if(!control.value){
            return {message: `${key}을(를) 입력해주세요`};
        }
        return null;
    }
}