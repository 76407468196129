<mat-chip-listbox
  [style.margin-left.px]="x"
  [style.margin-top.px]="y"
  style="display:inline-block; position:absolute"
><!--TODO 하드코딩. 나중에 빼야 함!-->
  @for (item of items; track $index) {
    <mat-chip-option 
      [value]="item.value"
      [selected]="$index === 0"
      (selectionChange)="onSelecteChange($event)"
      [matTooltip]="item.tooltip.content"
      [matTooltipPosition]="item.tooltip.position"
      matTooltipClass="custom-tooltip"
    >{{ item.name }}</mat-chip-option>
  }
</mat-chip-listbox>