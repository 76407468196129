<div 
  #scrollBody 
  class="chat-box chat-box-scroll" 
  [style.width]="width"
  [style.height]="height"
  [style.margin-left.px]="x"
  [style.margin-top.px]="y"
  (dragover)="onDragOver($event)"
  (drop)="onDrop($event)"
  (dragleave)="onDragLeave($event)"
>
  <ng-container #container></ng-container>
</div>