<div 
  [style.width]="width"
  [style.height]="height"
  [style.margin-left.px]="x"
  [style.margin-top.px]="y"
>
<mat-form-field class="form-field" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <textarea [disabled]="disabled" #inputText matInput
      [placeholder]="placeholder" [(ngModel)]="dynamicLayoutService.inputMessage" (keydown.enter)="onKeyDownEnter($event, inputText.value)"></textarea>
  </mat-form-field>
</div>