import { Component, Input } from '@angular/core';
import { DataAbstract } from '../../../../../core/classes/data.abstract';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrl: './text.component.scss'
})
export class TextComponent extends DataAbstract {

  public x: number = 0;
  public y: number = 0;
  public fontWeight: number = 0;
  public fontSize: string = "large";

  @Input() text: string = '';

  public override dataInit(): void {
    this.text = this.data.text;
    this.x = this.data.position.x;
    this.y = this.data.position.y;
    this.fontWeight = this.data.font.weight;
    this.fontSize = this.data.font.size;
  }

}