
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, map, Observable, timer } from "rxjs";
import OpenAI from "openai";
import { SlotItemSenderComponent } from "../items/slot-item-sender/slot-item-sender.component";
import { SlotItemConversationComponent } from "../items/slot-item-conversation/slot-item-conversation.component";
import { SlotItemSketchSenderComponent } from "../items/slot-item-sketch-sender/slot-item-sketch-sender.component";
import { ConditionalExecution } from "../../../../src/app/app.component";
import { SlotStoryAiSenderComponent } from "../items/slot-story-ai-sender/slot-story-ai-sender.component";
import { environment } from "../../../../src/environments/environment";
import * as CryptoJS from 'crypto-js';
@Injectable({ providedIn: 'root' })
export class AiService {

  private apiKey =  environment.gptKey; 
  private apiUrl = 'https://api.openai.com/v1/chat/completions';

  public messageValue:string = '';
  public senderComponent!:SlotItemSenderComponent;
  public geminiSenderComponent!:SlotStoryAiSenderComponent;
  public converstionComponent!:SlotItemConversationComponent;

  public novelPrompt = `
                  `;

  public messages: any[] = [
    { role: 'system', content: this.novelPrompt + '이 내용을 기반으로 답변을 해줘 질문자는 주인공이야' },
    { role: 'user', content: '안녕하세요.' } 
  ];


  public promptStates: any[] = [
    {
      name: '무협 작가 보조',
      type: 'assistant',
      flag: './assets/svg/users/user1.svg',
      prompt : '너는 소설가를 도와주는 보조 작가야'
    },
    {
      name: 'AI',
      type: 'assistant',
      flag: './assets/svg/users/user2.svg',
      prompt : '너는 천재야'
    },
    {
      name: '데이터 로더',
      type: 'data',
      flag: './assets/svg/users/user3.svg',
      prompt : '데이터를 불러오는 기능입니다.'
    },
  ];

  public selectedPromptImg = this.promptStates.first().flag;
  public selectedaiPromptImg = './assets/svg/items/google-gemini-icon.png';
  public selectedSavePromptImg = './assets/svg/items/item4.svg';

  public selectedItem = {
    name: '무협 작가 보조',
    type: 'assistant',
    flag: './assets/svg/users/user1.svg',
    prompt : '너는 소설가를 도와주는 보조 작가야'
  };

  constructor(private http: HttpClient) {
  }

  private encrypt(value: string): string {
    const ciphertext = CryptoJS.AES.encrypt(value, environment.base_url);
    return ciphertext.toString();
  }

  private decrypt(ciphertext: string): string {
    const bytes = CryptoJS.AES.decrypt(ciphertext, environment.base_url);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  async getOpenAISteam(message: any[]): Promise<Observable<string>> {

    const openai = new OpenAI({
      apiKey: this.decrypt(this.apiKey),
      dangerouslyAllowBrowser: true
    });

    const completion = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: message,
      stream: true,
    });

    return from(completion).pipe( // AsyncIterable을 Observable로 변환
      map(chunk => chunk.choices[0]?.delta?.content || '') // content 추출 및 빈 문자열 처리
    );
  }

  sendMessage(message: string[]): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.apiKey}` // 실제 API 키로 변경
    };

    const body = JSON.stringify({
      model: 'gpt-3.5-turbo',
      messages: message
    });

    return this.http.post(this.apiUrl, body, { headers });
  }

  setPrompt(selectedValue:string) {
    this.selectedItem = this.promptStates.find(d => d.name === selectedValue);
    this.selectedPromptImg = this.selectedItem.flag;
    this.messages.first().content = this.selectedItem.prompt;
    this.setChange(() => this.selectedItem.type === 'data');
  }

  @ConditionalExecution
  setChange(condition: () => boolean) {
    this.converstionComponent.items.first().title = this.selectedItem.prompt;
  }
}