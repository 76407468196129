import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { DataAbstract } from '../../../../../core/classes/data.abstract';

@Component({
  selector: 'app-button-name',
  templateUrl: './button-name.component.html',
  styleUrl: './button-name.component.scss'
})
export class ButtonNameComponent extends DataAbstract {

  public x: number = 0;
  public y: number = 0;
  public width: string = '';
  public height: string = '';
  public iconName: string = '';

  @Input() buttonName: string = '';
  @Output() click = new EventEmitter<any>();

  private readonly router = inject(Router);

  public override dataInit(): void {
    this.x = this.data.position.x;
    this.y = this.data.position.y;
    this.width = this.data.size.width;
    this.height = this.data.size.height;
    this.iconName = this.data.iconName;
    this.buttonName = this.data.buttonName;
  }

  public onClick(event: MouseEvent) {
    this.click.emit();
  }

}
