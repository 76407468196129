import { timer } from "rxjs";
import { AnswerBalloonHTMLComponent } from "../../../shared/components/molecules/answer-balloon-html/answer-balloon-html.component";
import { LiskovChatflowModel } from "../../models/liskov.chatflow.model";
import { BehaviorLogic } from "../abstract/behavior-logic.chatflow.abstract";
import { LogicChat } from "./chat.chatflow";

export class LogicComplain extends BehaviorLogic {

  constructor(public override liskovChatflow: LiskovChatflowModel){
    super(liskovChatflow);
  }

  override prepare(data?: data){
    this.liskovChatflow.service.index = 0;
    this.create(data);
  }

  override create(data?: data): void {
    this.events(this.liskovChatflow.service.index, data);
  }

  // private section = '';
  // private field1 = '';
  // private sendMessage = '';
  // private complainResultParam = '';

  // constructor(public override liskovChatflow: LiskovChatflowModel) {
  //   super(liskovChatflow);
  // }

  // override prepare(data?: data) {
  //   this.sendMessage = JSON.stringify(this.liskovChatflow.logicData.first())
  //   this.liskovChatflow.service.index = 0;
  //   this.create(data);
  // }

  // override create(data?: data): void {
  //   this.events(this.liskovChatflow.service.index, data);

  // }

  // override speak(data: any): Promise<void> | undefined {

  // callGPT1(data?: any) {
  //   this.liskovChatflow.service.socketService.isConnected = true;

  //   const request = {
  //     assiatant: '',
  //     prompt: '리스트 형식으로 정리해줘',
  //     param: this.sendMessage
  //   };

  //   this.liskovChatflow.service.socketService.sendMessage('call_gpt_prompt', request);
  //   this.liskovChatflow.logicManager.find('chat').prepare({
  //     message: '분석 중',
  //     component: AnswerBalloonHTMLComponent
  //   });

  //   let response: string = ``;
  //   const gptSubscription = this.liskovChatflow.service.socketService.getMessage('receive_message_gpt_prompt').subscribe((data: any) => {
  //     if (data == null) {
  //       //this.liskovChatflow.logicManager.find('chat').clear();
  //       gptSubscription.unsubscribe();
  //       this.liskovChatflow.service.socketService.isConnected = false;
  //       this.speak1(this.setComplainMessage(response));
  //       return;
  //     }
  //     response = response + data;
  //     this.liskovChatflow.logicManager.find('chat').create({ message: this.setComplainMessage(response) });
  //   });
  // }

  // callGPT2(result?: any) {
  //   this.liskovChatflow.service.socketService.isConnected = true;
  //   result = result + `<div style="font-weight: bold; font-size: 20px; margin: 10px;">○ 민원 결과</div>`;
  //   const request = {
  //     assiatant: '',
  //     prompt: `너는 환경공단 민원을 상담하는 직원이고 해당 내용은 민원인이 가진 민원인데 관련 법령을 찾아서 참고를 해서 민원인이 잘 이해할 수 있도록 시나리오 기반으로 정리를 해줘`,
  //     param: this.complainResultParam
  //   };

  //   this.liskovChatflow.service.socketService.sendMessage('call_gpt_prompt', request);

  //   let response: string = ``;
  //   const gptSubscription = this.liskovChatflow.service.socketService.getMessage('receive_message_gpt_prompt').subscribe((data: any) => {
  //     if (data == null) {
  //       gptSubscription.unsubscribe();
  //       this.liskovChatflow.service.socketService.isConnected = false;
  //       (this.liskovChatflow.logicManager.find('chat') as LogicChat).clear().subscribe(() => {
  //         return;
  //       });
  //       //this.speak1(this.setComplainMessage(response));
  //     }
  //     response = response + data;

  //     this.liskovChatflow.logicManager.find('chat').create({ message: result + `<div style="margin:10px; max-width:750px; overflow: hidden;"><pre style="white-space: pre-wrap;">${response} </pre></div>` });

  //    // this.liskovChatflow.logicManager.find('chat').create({ message: data + "!@!#!@#!@@!#!@#!@" });
  //     //this.liskovChatflow.logicManager.find('chat').create({ message: response });
  //   });
  // }

  // private setComplainMessage(data:string) {
  //   const message = `
  //   <div style="font-weight: bold; font-size: 20px; margin: 10px;">○ 민원내용</div>
  //   <div style="margin:10px; max-width:750px; overflow: hidden;"><pre style="white-space: pre-wrap;">${ this.sendMessage } </pre></div>
  //   <div style="font-weight: bold; font-size: 20px; margin: 10px;">○ 민원요약</div>
  //   <div style="margin:10px; max-width:750px; overflow: hidden;"> <pre style="white-space: pre-wrap;">${ data }  </pre></div>
  //   `
  //   return message;
  // }

  // speak2(data:string) {


  //   this.callGPT2(data)
  //   // this.liskovChatflow.logicManager.find('chat').create({ message: data + "!@!#!@#!@@!#!@#!@" });

  // }

  // speak1(data?: any) {
  //   this.section = '';
  //   this.field1 = '';
  //   let result = '';
  //   //this.liskovChatflow.logicManager.find('chat').create({ message: data + "!@!#!@#!@@!#!@#!@" });
  //   // this.liskovChatflow.logicManager.find('chat')?.prepare({
  //   //   message: '',
  //   //   component: AnswerBalloonHTMLComponent
  //   // });

  //   const getList = ["민원요지", "관련법령", "민원처리 결과요약", "처리결과 및 향후 대책", "처리기관", "처리부서", "처리일", "담당자"];
  //   const timerSubscriber = timer(500, 400).subscribe(d => {
  //     result = data +  this.getMessage(this.createSection(`${getList[d]}`, d));

  //     this.liskovChatflow.logicManager.find('chat')?.create({
  //       message: result
  //     });
  //     if (d === 7) {
  //       timerSubscriber.unsubscribe();
  //       //this.liskovChatflow.logicManager.find('chat')?.clear();
  //       this.speak2(result);
  //     }
  //   });
  // }

  //   const sorted = contList.sort((a, b) => b.count - a.count).first();
  //   if(sorted.count === 0) {
  //     sorted.data = null;
  //   }
  //   return sorted.data;
  // }

  // private createSection(data: string, count: number): string {
  //   const userData:any = this.getComplain();
  //   if (userData == null) {
  //     this.field1 = `<div class="field-row"><span class="title1">결과</span><span class="content">관련된 민원에 대한 결과가 없습니다.</span></div>`
  //   } else {
  //     if(data === '처리결과 및 향후 대책') {
  //       this.complainResultParam = userData[data];
  //     }
  //     this.field1 = this.field1 + `<div class="field-row"><span class="title1">${data}:</span><span class="content">${userData[data]}</span></div>`
  //   }
  //   return this.field1;
  // }

  // private getMessage(section: string) {
  //   const messsage = `
  //       <div>
  //           <section class="container">
  //               <ol>
  //                   <h2 class="field-title">민원 답변</h2>
  //                   <li class="field">
  //                       ${section}
  //                   </li>
  //               </ol>
  //           </section>
  //       </div>
  // `

  //   return messsage;
  // }
}
