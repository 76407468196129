<button 
  mat-stroked-button
  style="background-color: white;"
  (click)="onClick($event)"
  [style.margin-left.px]="x"
  [style.margin-top.px]="y"
  [style.width]="width"
  [style.height]="height"
>
  <mat-icon>{{ iconName }}</mat-icon>
  {{ buttonName }}
</button>
