import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { DataAbstract } from '../../../../core/classes/data.abstract';

@Component({
  selector: 'app-speak-intro',
  templateUrl: './speak-intro.component.html',
  styleUrl: './speak-intro.component.scss'
})
export class SpeakIntroComponent  extends DataAbstract implements OnInit{
  
  public url: string = '';
  public content: string ='';

  private readonly router = inject(Router);

  ngOnInit(): void {}

  public override dataInit(): void {
    this.url = this.data.url;
    // this.content = this.data.content;
    // this.container.nativeElement.innerHTML = this.content;
  }
  
  onRouterClick(){
    this.router.navigateByUrl('/category-manage');
  }
}
