import { Observable } from "rxjs";
import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { IFileDeleteRequest, IFileDeleteResponse, IFileGetRequest, IFileGetResponse, IFileReadRequest, IFileReadResponse, IFileUploadRequest, IFileUploadResponse, IFileVersionsGetRequest, IFileVersionsGetResponse } from "../../pages/category-manage/interfaces/file/file.dto";
import { HttpService } from "../services/http.service";
import { IFileRest } from "./file.http";
import { inject } from "@angular/core";

export class FileLegacyHttp implements IFileRest{
    private readonly httpService = inject(HttpService);

    get(request: IFileGetRequest): Observable<IFileGetResponse>{
        return this.httpService.Get(`/fileManage-legacy/${request.categoryId}`) as Observable<IFileGetResponse>;
    }

    upload(request: IFileUploadRequest){
        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        const formData = new FormData();
        for(let i = 0; i < request.files.length; i++){
            formData.append('files', request.files.item(i)!);
          }
        formData.append('categoryId', request.categoryId.toString());
        formData.append('path', request.path);
        formData.append('socketId', request.socketId);
        formData.append('improvedType', request.improvedType);
        return this.httpService.Post('/fileManage-legacy', formData, headers) as Observable<IFileUploadResponse>;
    }

    delete(request: IFileDeleteRequest){
        return this.httpService.Post('/fileManage-legacy/delete', request) as Observable<IFileDeleteResponse>;
    }

    // getVersions(request: IFileVersionsGetRequest): Observable<IFileVersionsGetResponse>{
    //     return this.httpService.Get('/fileManage-legacy/versions') as Observable<IFileVersionsGetResponse>;
    // }

    download(fileId: number): Observable<HttpResponse<Blob>>{
        return this.httpService.GetBlob(`/fileManage-legacy/download/${fileId}`);
    }
}