import { Router } from "@angular/router";
import { ButtonNameComponent } from "../../../pages/chatflow/components/atom/button-name/button-name.component";
import { LiskovChatflowModel } from "../../models/liskov.chatflow.model";
import { ChatflowLayout } from "../abstract/layout.chatflow.abstract";

export class LayoutChatHistoryPage extends ChatflowLayout {

    //private currentIndex: number = -1;
    
    constructor(
        private router: Router,
        public override liskovChatflow: LiskovChatflowModel
    ){
        super(liskovChatflow);
    }

    override prepare(data?: any): void {
        this.liskovChatflow.service.index = 0;
        this.container = data;
        this.create();
    }

    override create(data?: data): void {
        this.draw(this.liskovChatflow.service.index);
    }

    override buttonName(data: any): void {
        const buttonName = this.liskovChatflow.service.factoryService.createComponent(
            ButtonNameComponent,
            this.container,
            data
        );

        this.subscription.add(buttonName.instance.click.subscribe((d: any) => {
            this.router.navigateByUrl(`/chatbot`);
        }));
        this.draw(++this.liskovChatflow.service.index);
    }    

}