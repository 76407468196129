import { ButtonNameComponent } from "../../../pages/chatflow/components/atom/button-name/button-name.component";
import { ButtonComponent } from "../../../pages/chatflow/components/atom/button/button.component";
import { ChatBoxComponent } from "../../../pages/chatflow/components/atom/chat-box/chat-box.component";
import { ChipsComponent } from "../../../pages/chatflow/components/atom/chips/chips.component";
import { ExpansionPanelComponent } from "../../../pages/chatflow/components/atom/expansion-panel/expansion-panel.component";
import { IconButtonComponent } from "../../../pages/chatflow/components/atom/icon-button/icon-button.component";
import { InputComponent } from "../../../pages/chatflow/components/atom/input/input.component";
import { QuestionBalloonComponent } from "../../../shared/components/molecules/question-balloon/question-balloon.component";
import { LiskovChatflowModel } from "../../models/liskov.chatflow.model";
import { IExpansionPanelBoxData } from "../../store/expansion-panel-box/expansion-panel-box.reducer";
import { selectExpansionPanelBox } from "../../store/expansion-panel-box/expansion-panel-box.selector";
import { ChatflowLayout } from "../abstract/layout.chatflow.abstract";
import { LogicChat } from "../logics/chat.chatflow";
import { CardDialogComponent } from "../../../shared/components/molecules/card-dialog/card-dialog.component";

export class LayoutChatPage extends ChatflowLayout {

    private currentIndex: number = -1;

    constructor(public override liskovChatflow: LiskovChatflowModel){
        super(liskovChatflow);
    }

    override prepare(data?: any): void {
        this.liskovChatflow.service.index = 0;
        this.container = data;
        this.create();
    }

    override create(data?: data): void {
        this.draw(this.liskovChatflow.service.index);
    }

    private getQuestion(data?: string) {
        if (!this.liskovChatflow.service.dynamicLayoutService.inputMessage) return;


        if(this.liskovChatflow.service.logicType === 'mrc' && !this.liskovChatflow.service.vectordbService.currentCategory) {
            this.liskovChatflow.service.httpService.snackBar("카테고리를 선택해주세요. 카테고리 선택은 상단 메뉴에서 하실 수 있습니다.");
            return;
        }

        this.liskovChatflow.logicManager.find('chat')?.prepare({
            message: data ?? this.liskovChatflow.service.dynamicLayoutService.inputMessage,
            component: QuestionBalloonComponent
        });
        (this.liskovChatflow.logicManager.find('chat') as LogicChat).clear(data ?? this.liskovChatflow.service.dynamicLayoutService.inputMessage).subscribe((response) => {
            this.liskovChatflow.service.liskovChatflow.logicData.push(data ?? this.liskovChatflow.service.dynamicLayoutService.inputMessage);
            this.liskovChatflow.service.chatflow.scenario(this.liskovChatflow.service.logicType);

            this.liskovChatflow.service.dynamicLayoutService.inputMessage = '';
            this.liskovChatflow.service.liskovChatflow.clear();
        });
    }

    override iconButton(data: any): void {
        const iconButton = this.liskovChatflow.service.factoryService.createComponent(
            IconButtonComponent,
            this.container,
            data
        );
        this.subscription.add(iconButton.instance.click.subscribe((d: any) => {
            if (this.liskovChatflow.service.socketService.isConnected) {
                this.liskovChatflow.service.httpService.Get('/grpc/llm-cancel', { 'clientid': this.liskovChatflow.service.socketService.id })
                  .subscribe(d => {
                    if (!d.objectResult.description.includes('정상 처리 완료')) return;
                    this.liskovChatflow.service.socketService.isConnected = false;
                    (this.liskovChatflow.logicManager.find('chat') as LogicChat).clear(this.result).subscribe(() => {});
                    console.log('정상 처리 완료', this.result);
                  });
                return;
            }
            this.getQuestion();
        }));
        this.draw(++this.liskovChatflow.service.index);
    }

    override input(data: any): void {
        const input = this.liskovChatflow.service.factoryService.createComponent(
            InputComponent,
            this.container,
            data
        );
        this.subscription.add(input.instance.enter.subscribe((d: any) => {
            if (!d.value) return;
            this.getQuestion(d.value);
            input.instance.inputText.nativeElement.value = '';
        }));
        this.draw(++this.liskovChatflow.service.index);
    }

    override chips(data: any): void {
        const chips = this.liskovChatflow.service.factoryService.createComponent(
            ChipsComponent,
            this.container,
            data
          );
        this.subscription.add(chips.instance.selectChange.subscribe(d => {
            if (!d.selected) {
                this.liskovChatflow.service.logicType = 'default';
                this.liskovChatflow.service.prompt = '';
                return;
            }
            this.liskovChatflow.service.logicType = d.source._value;
            this.liskovChatflow.service.prompt = '';
        }));
        this.draw(++this.liskovChatflow.service.index);
    }

    override button(data: any): void {
        const button = this.liskovChatflow.service.factoryService.createComponent(
            ButtonComponent,
            this.container,
            data
        );
        this.subscription.add(button.instance.click.subscribe(d => {
            this.liskovChatflow.service.dynamicLayoutService.selectCategory();
        }));
        this.draw(++this.liskovChatflow.service.index);
    }

    override chatBox(data: any): void {
        // height의 경우, 이때, 값을 설정
        data.size.height = this.liskovChatflow.service.layoutService.getChatContainerHeight();

        this.liskovChatflow.service.dynamicLayoutService.chatBoxContainer = this.liskovChatflow.service.factoryService.createComponent(
            ChatBoxComponent,
            this.container,
            data
        );

        this.subscription.add(this.liskovChatflow.service.dynamicLayoutService.chatBoxContainer.instance.drop.subscribe(event => {
            console.log('chatbox drop', event);
            this.liskovChatflow.logicManager.find('chat').prepare({
                message: event.dataTransfer?.files[0].name,
                component: QuestionBalloonComponent
            });
            (this.liskovChatflow.logicManager.find('chat') as LogicChat).clear(event.dataTransfer?.files[0].name).subscribe(() => {
                this.liskovChatflow.service.liskovChatflow.logicData.push(event.dataTransfer?.files);
                this.liskovChatflow.service.chatflow.scenario('learn');
            });
        }));
        this.liskovChatflow.service.dynamicLayoutService.chatScrollBody = this.liskovChatflow.service.dynamicLayoutService.chatBoxContainer.instance.scrollBody.nativeElement;
        this.draw(++this.liskovChatflow.service.index);
    }

    override expansionPanel(data: any): void {
        this.subscription.add(this.liskovChatflow.service.store.select(selectExpansionPanelBox).subscribe(d => {
            d.components.forEach((value: IExpansionPanelBoxData) => {
                if (this.currentIndex < value.index) {
                    this.liskovChatflow.service.factoryService.createComponent(
                        ExpansionPanelComponent,
                        this.container,
                        value
                    );
                    this.currentIndex = value.index;
                }
            });
        }));
        this.draw(++this.liskovChatflow.service.index);
    }

    override buttonName(data: any): void {
        const button = this.liskovChatflow.service.factoryService.createComponent(
            ButtonNameComponent,
            this.container,
            data
        );
        this.subscription.add(button.instance.click.subscribe(d => {
            const dialogRef = this.liskovChatflow.service.dynamicLayoutService.dialog.open(CardDialogComponent, {
                panelClass: 'usage-guide',
                maxWidth:  '1000px'
            });
        }));
        this.draw(++this.liskovChatflow.service.index);
    }
}
