import { DialogOptionGenerator } from "./dialog.option";
import { FieldOption } from "./field.option";

export class FormConfigGenerator {
    generate<T extends FieldOption>(type:new () => T): T{
        return new type();
    }
    
    generateDialogTool(){
        return new DialogOptionGenerator();
    }

    generateDto(type: new () => any){
        return new type();
    }
}