import { Injectable } from "@angular/core";
import { CurrentUserService } from './current-user.service';

export interface HistoryData {
  id: string;
  user: string;
  message: any;
  data: Date;
}

@Injectable({ providedIn: 'root' })
export class HistoryService {

  public currentChatPageId: string = '';
  public pageHistoryList: any | null = null;
  public allhistoryList: any | null = null;
  public historyMetadata = [];

  constructor(private currentUser: CurrentUserService) {}

  public getCurrentDate() {
    return new Date();
  }

  public getDateString(date: string) {
    return date.split('T')[0].split('-').join('.');
  }

  public groupHistoryList() {
    const groupHistory = this.allhistoryList.reduce((acc: any, obj: any) => {
      const group = acc.find((group: any) => this.getDateString(group.header) === this.getDateString(obj.pageDate)); // 현재 날짜로 그룹이 이미 존재하는지 확인

      let firstUserItem = obj.value.find((item: any) => item.user === this.currentUser.userData.name); // obj.value 배열에서 '유저 이름'을 가진 첫 번째 항목 찾기
      
      if (!firstUserItem) {
        firstUserItem = {
          user : obj.value.first().user,
          message: obj.value.first().message
        }
      }
      
      if (group) { // 그룹이 존재하면 현재 객체의 key를 그룹의 items 배열에 추가
        group.items.push({ 
          id: obj.key, 
          type: firstUserItem.user, 
          message: firstUserItem.message 
        });
      }
      else { // 그룹이 존재하지 않으면 새로운 그룹 생성
        acc.push({ 
          header: obj.pageDate, 
          items: [{ 
            id: obj.key, 
            type: firstUserItem.user, 
            message: firstUserItem.message
           }] 
        });
      }
      
      return acc;
    }, []);

    groupHistory.reverse().forEach((group: any) => {
      group.items.reverse();
    });

    return groupHistory;
  }

}