
<div>
  <h2 mat-dialog-title>KGPT 사용안내</h2>
  <mat-dialog-content class="mat-typography">
    <mat-tab-group>
      <mat-tab label="민원답변 생성">
        <div style="width: 700px; height: 500px;" class="hide-scrollbar">
          <h3 style="font-weight: 600;">민원 답변 생성</h3>
          <p>답변이 필요한 민원을 질문하면 등록된 문서를 토대로 민원 안내, 관련 법률을 제공하는 기능입니다.</p>
          <img src="./assets/images/environment1.png" style="width: 700px; height: 500px;">
        </div>
      </mat-tab>
      <mat-tab label="문서 지식 검색">
        <div style="width: 700px; height: 500px;" class="hide-scrollbar">
          <h3 style="font-weight: 600;">문서 지식 검색</h3>
          <p>업로드 된 문서를 기반으로 질문에 답변을 제공하며, 참조한 문서 정보를 제공하는 기능입니다.</p>
          <img src="./assets/images/environment2.png" style="width: 700px; height: 500px;">
          <br/>
          <br/>
          <h3 style="font-weight: 600;">AI 답변</h3>
          <ul>
            <li class="disc-li">카테고리에 업로드 된 문서를 기반으로 답변합니다.</li>
            <li class="disc-li">답변을 위해 사용된 문서와 페이지 미리보기를 출력이 가능합니다.</li>
            <li class="disc-li">답변을 위해 사용된 문서를 요약합니다.</li>
          </ul>
          <br/>
          <h3 style="font-weight: 600;">카테고리 관리</h3>
          <ul>
            <li class="disc-li">카테고리 추가, 이름 변경,  삭제, 카테고리 순서 변경이 가능합니다.</li>
            <li class="disc-li">카테고리 마우스 우클릭 후 추가, 이름 변경을 클릭합니다.
              <br>
              <img src="./assets/images/environment3.png" style="width: 700px; height: 400px;">
            </li>
            <li class="disc-li">적용하고 싶은 이름을 입력 후 저장 버튼을 누릅니다.
              <img src="./assets/images/environment4.png" style="width: 700px; height: 500px;">
            </li>
          </ul>
          <br/>
          <br/>
          <br/>
          <br/>
          <h3 style="font-weight: 600;">카테고리 순서 변경</h3>
          <p>카테고리를 드래그 후 원하는 위치에서 드롭 합니다. 카테고리 순서 변경은 같은 깊이의 카테고리에서만 가능합니다.</p>
          <br/>
          <h3 style="font-weight: 600;">문서 학습</h3>
          <ol>
            <li>1. 좌측 화면에서 문서를 학습시킬 카테고리를 선택합니다.</li>
            <li>2. 우측 화면에서 문서 등록 버튼을 클릭합니다.</li>
            <li>3. 문서 하나, 혹은 여러 개를 선택해 업로드 합니다.</li>
            <li>4. 임베딩 문서 타입(기본/국민신문고)을 선택해 더 나은 정확도로 문서를 학습시킬 수 있습니다.</li>
          </ol>
        </div>
      </mat-tab>
      <mat-tab label="프롬프트 요약">
        <div style="width: 700px; height: 300px;" class="hide-scrollbar">
          <h3 style="font-weight: 600;">프롬프트 요약</h3>
          <p>문서를 입력하면 설정한 프롬프트에 따라 목적에 맞게 요약된 답변을 제공합니다.</p>
          <p>프롬프트를 선택하거나 직접 입력하여 사용 할 수 있습니다.</p>
          <br/>
          <br/>
          <h3 style="font-weight: 600;">프롬프트 예시</h3>
          <ol>
            <li>1. 회의록 작성 : [회의명]의 회의 내용을 바탕으로 회의록을 작성해 주세요</li>
            <li>2. 교육 자료 생성 : 교육용 시나리오 형태의 [주제]를 작성해 주세요</li>
            <li>3. 메일 작성 : [수신자]에게 [목적]에 대한 공식적인 메일을 작성해 주세요</li>
            <li>4. 보고서 작성 : [업무명]에 대한 업무 보고서를 작성해 주세요</li>
          </ol>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true">닫기</button>
  </mat-dialog-actions>
</div>
