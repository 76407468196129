import { FieldConfigData } from "../../interfaces/dynamic-form-option.interface";
import { FieldConfig } from "../../interfaces/dynamic-form.interface";
import { FieldOption } from "../../util/generator/field.option";
import { DynamicField } from "./dynamic-field.abstract";

/**
 * 텍스트 영역 데이터 바인딩 추상 클래스
 */
export abstract class AbstractTextField extends DynamicField {
    config!: FieldConfig;
    
    override init(initData: FieldConfigData): void {
        const {key, title, initValue } = initData
        this.config = this.generator.generate(FieldOption)
            .key(key).title(title).initValue(initValue).build();
    }

}