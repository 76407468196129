import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FieldConfigData } from '../../interfaces/dynamic-form-option.interface';
import { AbstractTextField } from '../../components/abstracts/text-field.abstract';
import { AbstractButtonFeild } from '../../components/abstracts/button-field.abstract';
import { FormControl } from '@angular/forms';
import { DialogService } from '../../components/dialogs/dialog-layout/dialog.service';

@Component({
  selector: 'app-buttonfield',
  templateUrl: './buttonfield.component.html',
  styleUrl: './buttonfield.component.scss'
})
export class ButtonFieldComponent extends AbstractButtonFeild implements OnInit {
  
  @Input('initData') initData!: FieldConfigData;

  constructor(
    private dialogService:DialogService
  ){
    super();
  }

  ngOnInit(): void {
    this.init(this.initData);
  }

  emit(){
    console.log('클릭');
    this.dialogService.eventListener$.next({event: 'open', param: undefined});
  }
}
