import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { HttpService } from "../services/http.service";
import { IChatHistoryRest, IChatHistoryRequest } from "./chat-history.http";

export class ChatHistoryLegacyHttp implements IChatHistoryRest {

  private readonly httpService = inject(HttpService);

  create(request: IChatHistoryRequest): Observable<any> {
    return this.httpService.Post('/history', request);
  }

  update(request: IChatHistoryRequest): Observable<any> {
    return this.httpService.Post('/history', request);
  }

  get(userIdx: number): Observable<any> {
    return this.httpService.Get(`/history/${userIdx}`);
  }

  delete(request: IChatHistoryRequest): Observable<any> {
    return this.httpService.Get(`/history/delete/${request.userIdx}/${request.chatPageId}`);
  }

}