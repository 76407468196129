import { Component, Input, OnInit } from '@angular/core';
import { FieldConfig, FormOptionType, TextField } from '../../interfaces/dynamic-form.interface';
import { FieldConfigData } from '../../interfaces/dynamic-form-option.interface';
import { FieldOption } from '../../util/generator/field.option';
import { DynamicField } from '../../components/abstracts/dynamic-field.abstract';
import { AbstractTextField } from '../../components/abstracts/text-field.abstract';

@Component({
  selector: 'app-textfield',
  templateUrl: './textfield.component.html',
  styleUrl: './textfield.component.scss'
})
export class TextfieldComponent extends AbstractTextField implements OnInit {
  
  @Input('initData') initData!: FieldConfigData;

  ngOnInit(): void {
    this.init(this.initData);
  }
}
