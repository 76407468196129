import { AnswerBalloonComponent } from "../../../shared/components/molecules/answer-balloon/answer-balloon.component";
import { ChatflowLogic } from "./logic.chatflow.abstract";
import { IBehaviorLogic } from "../interface/behavior-logic.chatflow.interface";
import { LiskovChatflowModel } from "../../models/liskov.chatflow.model";
import { finalize } from "rxjs";
import { AnswerBalloonHTMLComponent } from "../../../shared/components/molecules/answer-balloon-html/answer-balloon-html.component";

export abstract class BehaviorLogic extends ChatflowLogic implements IBehaviorLogic {

  constructor(public override liskovChatflow: LiskovChatflowModel) {
    super(liskovChatflow);
  }

  async behavior(data: any) {
    await (this as any)[data.node.category](data);
  }

  concat(data: any) {
    const index = this.jsonData.findIndex((node: any) => node.id === data.id);
    let concatData: string = '';
    if (index > 0) {
      const concatNode = this.jsonData.slice(0, index);
      concatNode.forEach((node: any) => {
        concatData = concatData + JSON.stringify(node.node.result[0])
      });
      this.liskovChatflow.logicManager.find('chat')?.prepare({
        message: concatData,
        component: AnswerBalloonComponent
      });
    }
    else {
      concatData = 'concat 할 데이터가 없습니다.';
      this.liskovChatflow.logicManager.find('chat')?.prepare({
        message: concatData,
        component: AnswerBalloonComponent
      });
    }
    return Promise.resolve(++this.liskovChatflow.service.index).then((index) => {
      this.events(index, concatData);
    });
  }

  llm(data?: any): void {
    this.liskovChatflow.service.socketService.isConnected = true;

    if (this.liskovChatflow.service.logicType == 'complain') {
      this.liskovChatflow.service.socketService.sendMessage('call_llm', {
        prompt: this.jsonData.userValue,
        category: '1/2',
        templateName: 'default'
      });
    }
    else {
      this.liskovChatflow.service.socketService.sendMessage('call_llm', {
        prompt: this.jsonData.userValue,
        category: this.liskovChatflow.service.vectordbService.currentCategory?.path
      });
    }

    this.liskovChatflow.logicManager.find('chat')?.prepare({
      message: data.node.result[0],
      component: AnswerBalloonHTMLComponent
    });

    let response: string = '';
    let messageReplace: string = '';
    let jsonArray: any = [];
    const llmSubscription = this.liskovChatflow.service.socketService.getMessage('receive_message').pipe(
      finalize(() => {
        const mrcArray: any = [];
        jsonArray.forEach((mrc: any) => {
          mrcArray.push({
            index: ++this.liskovChatflow.service.solutionService.index,
            question: this.jsonData.userValue,
            documentTitle: mrc.metadata.source,
            documentContent: mrc.page_content,
            documentPage: `${mrc.metadata.page + 1} page`
          });
        });

        if (mrcArray) {
          this.liskovChatflow.service.historyService.historyMetadata = mrcArray;
          this.liskovChatflow.logicManager.find('metadata')?.create(mrcArray);
        }

        // this.result = this.result + `<div style="margin:10px; max-width:750px; overflow: hidden;"><pre style="white-space: pre-wrap;">${ response }</pre></div>`;
        this.result = this.result + `${ response }`;
        return Promise.resolve(++this.liskovChatflow.service.index).then((index) => {
          this.events(index);
        });
      })
    ).subscribe((data: any) => {
      if (data.message === 'end queue') {
        llmSubscription.unsubscribe();
        this.liskovChatflow.service.socketService.isConnected = false;
        return;
      }

      if ((data.message as string)?.includes("{")) {
        messageReplace = data.message.replace(/\0/g, '|||').replace(/[\r\n]+/g, '|||');
        jsonArray.push(JSON.parse(messageReplace));
      }
      else if (data.message != undefined) {
        response = response.concat(data.message);
        // this.liskovChatflow.logicManager.find('chat')?.create({ message: this.result + `<div style="margin:10px; max-width:750px; overflow: hidden;"><pre style="white-space: pre-wrap;">${ response }</pre></div>` });
        this.liskovChatflow.logicManager.find('chat')?.create({ message: this.result + `${ response }` });
      }
    });
  }

  prompt(data?: any) {
    this.liskovChatflow.service.socketService.isConnected = true;

    if (data.node.isAwait) {
      this.liskovChatflow.service.callPrompt = true;
      this.liskovChatflow.logicManager.find('chat').prepare({
        message: data.node.result[0],
        component: AnswerBalloonHTMLComponent
      });

      const ref = this.liskovChatflow.service.openBottomSheet();
      ref.afterDismissed().subscribe(() => {
        this.liskovChatflow.service.callPrompt = false;
        const userPrompt = `<div style="margin:10px; max-width:750px; overflow: hidden;">프롬프트 내용 : ${this.liskovChatflow.service.prompt}</div>`;
        this.result += userPrompt;
        this.liskovChatflow.logicManager.find('chat').create({
          message: this.result
        });
        return Promise.resolve(++this.liskovChatflow.service.index).then((index) => {
          this.events(index);
        });
      });
      return;
    }
    this.liskovChatflow.service.prompt = data.node.result[0];
    this.result += `<div style="margin:10px; max-width:750px; overflow: hidden;"><pre style="white-space: pre-wrap;">${ this.liskovChatflow.service.prompt }</pre></div>`;
    return Promise.resolve(++this.liskovChatflow.service.index).then((index) => {
      this.events(index);
    });
  }

  callGPT(data?: any) {
    this.liskovChatflow.service.socketService.isConnected = true;

    const request = {
      assiatant: '',
      prompt: (this.liskovChatflow.service.prompt == '') ? data.node.result.first() : this.liskovChatflow.service.prompt, // prompt 함수를 통해 prompt 받아옴
      param: this.jsonData.userValue
    };

    this.liskovChatflow.service.socketService.sendMessage('call_gpt_prompt', request);

    let response: string = '';
    const gptSubscription = this.liskovChatflow.service.socketService.getMessage('receive_message_gpt_prompt').pipe(
      finalize(() => {
        return Promise.resolve(++this.liskovChatflow.service.index).then((index) => {
          this.result += `<div style="margin:10px; max-width:750px; overflow: hidden;"><pre style="white-space: pre-wrap;">${ response }</pre></div>`;
          this.events(index);
        });
      })
    ).subscribe((data: any) => {
      if (data == null) {
        gptSubscription.unsubscribe();
        this.liskovChatflow.service.socketService.isConnected = false;
        return;
      }
      response = response.concat(data);
      this.liskovChatflow.logicManager.find('chat').create({ message: this.result + `<div style="margin:10px; max-width:750px; overflow: hidden;"><pre style="white-space: pre-wrap;">${ response }</pre></div>` });
    });
  }

  category(data?: any) {
    console.log('category', data);
    this.liskovChatflow.logicManager.find('chat').prepare({
      message: data.node.result.first(),
      component: AnswerBalloonComponent
    });

    this.liskovChatflow.service.dynamicLayoutService.selectCategory().subscribe(d => {
      this.liskovChatflow.logicManager.find('chat').create({
        message: `선택한 카테고리는 [${this.liskovChatflow.service.vectordbService.currentCategory?.myData?.name}] 입니다.`
      });
      return Promise.resolve(++this.liskovChatflow.service.index).then((index) => {
        this.events(index);
      });
    });
  }

  embedding(data?: any) {
    // 파일 정보 this.ownData.value
    console.log('embedding', data, 'ownData', this.jsonData);
    //this.liskovChatflow.service.categoryManageRest.postFilesUpload()
    return Promise.resolve(++this.liskovChatflow.service.index).then((index) => {
      this.events(index, data);
    });
  }

  private getComplain() {
    const getUserString = this.jsonData.userValue.split(' ');
    const contList: any[] = [];

    this.liskovChatflow.service.e_test.forEach((e,i) => {
      const getAllString = JSON.stringify(e).split(' ');
      let count = 0;
      for (const value of getUserString) {
        if (getAllString.includes(value)) {
          count++;
        }
      }
      contList.push({count: count, data: e});
    });

    const sorted = contList.sort((a, b) => b.count - a.count).first();
    if(sorted.count === 0) {
      sorted.data = null;
    }
    return sorted.data;
  }

  complain(data?: any) {
    // 민원 결과 내용 가져와서 프롬프트 먹여서 요약하기
    const userData: any = this.getComplain();

    if (userData == null) {
      this.jsonData.userValue =  `관련 민원을 찾진 못했지만 질문을 기반으로 해서 결과물을 출력해보겠습니다. 질문 : ${this.jsonData.userValue}`;
    }
    else {
      this.jsonData.userValue = userData['처리결과 및 향후 대책'];
    }

    this.callGPT(data);
  }

}
