import { Component } from '@angular/core';
import { DialogService, DynamicRequrestParam, EventParam } from '../dialog.service';
import { MembershipService } from '../../../../membership.service';
import { CreateUserDto } from '../../../../dto/create-user.dto';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  constructor(
    private dialogService: DialogService
  ){}

  close(){
    this.dialogService.eventListener$.next({ event: 'close', param: undefined });
  }
  update(){
    const eventParam: EventParam = {
      event: ''
    }
    const param: DynamicRequrestParam = {
      service: MembershipService,
      method: 'testJwt',
      paramType: CreateUserDto
    }
    eventParam.param = param;
    this.dialogService.eventListener$.next({ event: 'submit', param });
  }
}
