<div class="inner-form">
    <div class="item-left form-row">
        {{config.title}}
    </div>
    <div class="item-right">
        <mat-form-field appearance="fill" class="custom-form-field">
            <mat-label>{{config.label}}</mat-label>
            <input matInput [formControl]="control" [placeholder]="config.placeholder" [matTooltip]="config.tooltip">
        </mat-form-field>
    </div>
</div>

