import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function email(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value){
            return null;
        }
        
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const valid = regex.test(control.value);
        return valid ? null : {message: '유효하지 않은 이메일 형식입니다.'};
    }
}