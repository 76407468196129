<div class="greeting">
    <div class="pl-20 font16" #container>
        <!--템플릿을 그대로 넣을 수는 없을까?-->
        <h2 class="title">무엇을 도와 드릴까요?</h2>
        안녕하세요<br>저는 OlamSpace의 AI 어시스턴스입니다. <br>
        저는 문서내의 내용을 카테고리별로 검색하고 요약할 수 있어요<br>
    </div>				
    <div class="more">
        <ul>
            <li class="p5"><a class="btn-detail pointer" [style.background-image]="'url(./assets/images/ico-arrow.png)'" (click)="onRouterClick()">내 카테고리 보기</a></li>
            <li class="p5"><a class="btn-detail pointer" [style.background-image]="'url(./assets/images/ico-arrow.png)'" (click)="onRouterClick()">원하는 카테고리가 없으시면</a></li>
        </ul>			
    </div>
</div>