import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { IKeyValue } from '../models/liskov.model';
import { v4 as uuidv4 } from 'uuid';
import { INode } from '../../pages/chatflow/interfaces/chatflow.interface';

export interface ILogic {
  title: string;
  scenario: IKeyValue[];
}

@Injectable({
  providedIn: 'root'
})
export class LogicService {

  public nodeDetailDrawer!: MatDrawer;
  public chatflowContainer: any = [];
  public nodeDetailContainer!: any;
  public currentTabIndex: number = 0;

  public selectedLogic!: string;

  public chatflowComponentRefs: any = {
    "main": [],
    "chatbot": [],
  };
  public chatflow: any = {
    "main": [{
      id: this.getGUID(),
      type: 'speak',
      node: {
        category: 'speak',
        result: ['안녕하세요. KGPT 입니다'],
        isAwait: false
      },
      index: 0,
      left: false,
      right: false,
    }, {
      id: this.getGUID(),
      type: 'slot',
      node: {
        category: "slot",
        result: [
          "환경공단 메인", "개발 진행중", "개발 진행중", "개발 진행중",
          "개발 진행중", "개발 진행중", "개발 진행중", "개발 진행중", "개발 진행중"
        ],
        isAwait: false
      },
      index: 1,
      left: false,
      right: false
    }],
    "chatbot": [{
      id: this.getGUID(),
      type: 'speak',
      node: {
        category: 'speak',
        result: ['안녕하세요. KGPT 입니다'],
        isAwait: false
      },
      index: 0,
      left: false,
      right: false,
    }]
  };
  public logics = ['main', 'chatbot'];

  constructor() {}

  public getGUID() {
    const id = uuidv4();
    return id;
  }

  public getAvailableIndex(chatflow: any) {
    const indexes = chatflow.map((d: INode) => d.index).sort((a: number, b: number) => a - b);
    const availableIndex = indexes.findIndex((value: number, index: number) => value > index);
    return availableIndex !== -1 ? availableIndex : indexes.length;
  }

  public getLogicKey(name: string) {
    const key = localStorage.getItem(name);
    if (!key) return;
    return JSON.parse(key).scenario[0].key
  }

  public getLogicValues(name: string) {
    const value = localStorage.getItem(name);
    if (!value) return;
    return JSON.parse(value).scenario[0].value;
  }

  public setLogic(name: string) {
    const logic: ILogic = {
      "title": name,
      "scenario": [
        {
          "key": "layout", //system
          "value": this.chatflow[name]
        }
      ]
    };
    
    this.chatflow[name].sort((a: { index: number; }, b: { index: number; }) => a.index - b.index);
    localStorage.setItem(name, JSON.stringify(logic));
  }

  public getChatflow(name: string) {
    return this.chatflow[name]
  }

  public setChatflow(name: string, data: any) {
    if (!(name in this.chatflow)) {
      this.chatflow[name] = [];
    }
    const index = this.chatflow[name].findIndex((d: any) => d.id === data.id);

    if (index !== -1) {
      this.chatflow[name][index] = {
        ...this.chatflow[name][index],
        node: {
          ...this.chatflow[name][index].node,
          ...data.node
        }
      };
    }
    else this.chatflow[name].push(data);
  }

  public getChatflowComponentRefLogic(name: string) {
    return this.chatflowComponentRefs[name];
  }

  public getChatflowComponentRef(name: string, id: string) {
    return this.chatflowComponentRefs[name].find((d: any) => d.id === id);
  }

  public setChatflowComponentRef(name: string, data: any) {
    if (!(name in this.chatflowComponentRefs)) {
      this.chatflowComponentRefs[name] = [];
    }
    this.chatflowComponentRefs[name].push(data);
  }

}
