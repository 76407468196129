<button 
  mat-stroked-button
  style="background-color: white;"
  (click)="onClick($event)"
  [style.margin-left.px]="x"
  [style.margin-top.px]="y"
  [matTooltip]="'카테고리 선택. \n 문서 지식 검색에서 사용되는 카테고리를 선택합니다.'"
  matTooltipPosition="above"
   matTooltipClass="custom-tooltip"
>
  <mat-icon>folder</mat-icon>
  {{ dynamicLayoutService.buttonName }}
</button>
