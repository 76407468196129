import { Component } from '@angular/core';
import { DataAbstract } from '../../../../core/classes/data.abstract';

@Component({
  selector: 'app-answer-balloon',
  templateUrl: './answer-balloon.component.html',
  styleUrl: './answer-balloon.component.scss',
})
export class AnswerBalloonComponent extends DataAbstract {

  public answer!: string;

  public override dataInit(): void {
    this.answer = this.data;
  }
  
}
